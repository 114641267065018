<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle>
        {{ formattedItem.cantllaves }} {{ 'llave'|pluralize(formattedItem.cantllaves, 's') }},
        {{ formattedItem.cantalarmas }} {{ 'alarma'|pluralize(formattedItem.cantalarmas, 's') }} y
        {{ formattedItem.cantmandos }} {{ 'mando'|pluralize(formattedItem.cantmandos, 's') }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        icon
        @click.stop="clickListItemMenu(formattedItem)"
      >
        <v-icon>{{ $vuetify.icons.values.more }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'
import { LLAVERO } from '@/utils/consts'
import colors from 'vuetify/lib/util/colors'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = nonEmpty`LLavero ${item.codigo}`
      item.subtitle = item.mensaje
      item.retirado = item.estado === LLAVERO.estados.retirado
      if (item.retirado) {
        item.avatarColor = colors.red.base
        item.avatar = 'R'
      } else {
        item.avatarColor = colors.green.base
        item.avatar = 'T'
      }
      return item
    }
  },
  methods: {
    clickListItemMenu (item) {
      this.$emit('click-list-item-menu', item)
    }
  }
}
</script>
